import React, { FC, useMemo } from 'react';
import {
  Network,
  useExplorerClusterQuery,
  useExplorerRiskQuery,
  useMeQuery,
} from '@apolloGenerated';
import { CopyHash, Typography } from '@rubin-dev/goblin';
import { Blur, ListItem } from '@shared/ui';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { RiskDot } from '@component/NewRisk/ui/RiskDot/RiskDot';
import { amount, format } from '@aml/amount';
import { BalanceTooltip, TransactionsTooltip } from '@component/Address';
import { BigNumber } from 'bignumber.js';
import { useActivityPeriod } from '@component/Address/libs/hooks/useActivityPeriod';
import { useClusterRisk } from '@component/Address/libs/hooks/useClusterRisk';
import { openAddress } from '@shared/libs';
import { RouterName } from '../../../../router';
import { getRiskColor } from '@helpers/address';

export type ClusterMainInfoProps = {
  wid: string;
  network: Network;
  hideRisk?: boolean;
  hideCluster?: boolean;
  hideTransactions?: boolean;
  hideBalance?: boolean;
  hideActivity?: boolean;
  hideCategory?: boolean;
  hideRootAddress?: boolean;
};
export const ClusterMainInfo: FC<ClusterMainInfoProps> = ({
  wid,
  network,
  hideRisk,
  hideCluster,
  hideTransactions,
  hideBalance,
  hideActivity,
  hideCategory,
  hideRootAddress,
}) => {
  const { data: user } = useMeQuery({ fetchPolicy: 'network-only' });
  const { t } = useTranslation();
  // RISK
  const { data: walletData } = useExplorerClusterQuery({
    variables: { network, id: wid },
  });
  const { data: riskData } = useExplorerRiskQuery({
    variables: { network, address: wid },
    skip: hideRisk,
  });
  const stats = walletData?.explorerClusterStats?.stats;
  const wallet = walletData?.explorerCluster?.cluster;
  const { risk: clusterRisk, category } = useClusterRisk(Number(wallet?.riskCategory));
  const addressRisk = riskData?.explorerRisk?.risk;
  const risk = Number(clusterRisk || addressRisk?.risk);
  const categoryReportedName = addressRisk?.reported?.name;

  // TRANSACTIONS
  const total = stats?.totalCount || 0;
  const totalSent = stats?.sentCount || 0;
  const totalReceived = stats?.receivedCount || 0;

  // BALANCE
  const formatBalance = (val?: string) =>
    Number(val) > 0 ? amount(BigNumber(val || 0).toString(10), network, false) : 0;
  const balance = formatBalance(stats?.totalAmount);
  const balanceIn = formatBalance(stats?.receivedAmount);
  const balanceOut = formatBalance(stats?.sentAmount);

  // ACTIVITY
  const getActivityPeriod = useActivityPeriod();

  const expireDate = useMemo(
    () => user?.me.billing.analyzerSubscriptionExpireDate,
    [user?.me.billing.analyzerSubscriptionExpireDate],
  );

  return (
    <>
      <div className={styles.addressInfoMain}>
        {((!hideRisk && !isNaN(risk) && categoryReportedName) || !expireDate) && (
          <ListItem
            className={styles.addressInfoMain__item}
            label={`${t('strings.riskscore')}:`}
            value={
              expireDate ? (
                <Typography className={styles.addressInfoMain__risk} variant="head-16">
                  <RiskDot risk={risk} size={12} />
                  {risk}%
                </Typography>
              ) : (
                <Blur to={RouterName.TariffAnalyzer}>
                  <RiskDot risk={Number(risk)} size={12} />
                  000%
                </Blur>
              )
            }
          />
        )}
        {(!!(!hideCluster && wallet) || !expireDate) && (
          <ListItem
            className={styles.addressInfoMain__item}
            label={`${t('mention.cluster')}:`}
            value={
              expireDate ? (
                wallet?.owner || 'Entity'
              ) : (
                <Blur to={RouterName.TariffAnalyzer}>
                  <Typography variant="head-16" color="error-2">
                    Owner
                  </Typography>
                </Blur>
              )
            }
          />
        )}
        {!hideRootAddress && (
          <ListItem
            className={styles.addressInfoMain__item}
            label={`${t('titles.rootAddress')}:`}
            value={
              <CopyHash
                hash={wid}
                variant="body-16"
                shorted
                onClickHash={() => openAddress(network, wid)}
              />
            }
          />
        )}
        {(!!(!hideCategory && (category || categoryReportedName)) || !expireDate) && (
          <ListItem
            className={styles.addressInfoMain__item}
            label={`${t('links.category')}:`}
            value={
              expireDate ? (
                <Typography variant="head-16" style={{ color: getRiskColor(risk) }}>
                  {category || categoryReportedName}
                </Typography>
              ) : (
                <Blur to={RouterName.TariffAnalyzer}>
                  <Typography variant="head-16" color="error-2">
                    Category Name
                  </Typography>
                </Blur>
              )
            }
          />
        )}
        {!!(!hideTransactions && walletData) && (
          <ListItem
            className={styles.addressInfoMain__item}
            label={
              <>
                {`${t('strings.transactions')}:`}{' '}
                <TransactionsTooltip
                  total={format(total, 0)}
                  totalReceive={format(totalReceived, 0)}
                  totalSent={format(totalSent, 0)}
                  dense
                  network={network}
                />
              </>
            }
            value={format(total, 0)}
          />
        )}
        {!!(!hideBalance && walletData) && (
          <ListItem
            className={styles.addressInfoMain__item}
            label={
              <>
                {`${t('strings.balance')}:`}{' '}
                <BalanceTooltip
                  totalIn={`${balanceIn} ${network}`}
                  totalOut={`${balanceOut} ${network}`}
                  dense
                  network={network}
                />
              </>
            }
            value={`${balance} ${network}`}
          />
        )}
        {!!(!hideActivity && walletData) && (
          <ListItem
            className={styles.addressInfoMain__item}
            label={`${t('titles.activity')}:`}
            value={getActivityPeriod({ first: stats?.firstTx, last: stats?.lastTx })}
          />
        )}
      </div>
    </>
  );
};
