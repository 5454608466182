import React, { FC } from 'react';
import { openAddress, openTransaction, openWallet } from '@shared/libs';
import { CopyHash, TypographyVariantType } from '@rubin-dev/goblin';

export type HashType = 'address' | 'transaction' | 'wallet';
export type TableCellHashProps = {
  hash: string;
  label?: string;
  type?: HashType;
  network?: string;
  longer?: boolean;
  once?: boolean;
  variant?: TypographyVariantType;
  className?: string;
  onClick?(hash: string): void;
};
export const TableCellHash: FC<TableCellHashProps> = ({
  hash,
  label,
  longer,
  type,
  network,
  onClick,
  variant,
  once,
  className,
}) => {
  const opened = !!(type || onClick);
  const handleClick = () => {
    onClick && onClick(hash);
    if (!network || !type) return;
    switch (type) {
      case 'transaction':
        openTransaction(network, hash);
        break;
      case 'address':
        openAddress(network, hash);
        break;
      case 'wallet':
        openWallet(network, hash);
        break;
    }
  };
  return (
    <CopyHash
      label={label}
      hash={hash}
      onClickHash={opened ? handleClick : null}
      shorted={!longer}
      variant={variant}
      className={className}
      once={once}
    />
  );
};

export const renderCellHash = (args: TableCellHashProps) => <TableCellHash {...args} />;
