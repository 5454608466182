import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  AddressTransaction,
  AddressTransactionsFilterTxType,
  AddressTransactionsOrderField,
  Maybe,
  Network,
  OrderDirection,
  useExplorerFindTransactionsByAddressCountQuery,
  useExplorerFindTransactionsByAddressQuery,
} from '@apolloGenerated';
import { getNetworkEnum } from '@helpers/address';
import { useTranslation } from 'react-i18next';
import {
  ArrowLeftLongIcon,
  ArrowRightLongIcon,
  Button,
  LoaderOverlay,
  SortType,
  Spacer,
  TableController,
  Toggle,
  ToggleItem,
  VisualizationDenseIcon,
} from '@rubin-dev/goblin';
import { headers } from './constants';
import {
  renderCellAmount,
  renderCellChevron,
  renderCellDate,
  renderCellHash,
} from '@shared/ui';
import { renderCellReceiveAddresses } from './shared/TableCellReceiveAddresses';
import styles from './styles.module.scss';
import cx from 'classnames';
import { openVisualizationTransaction } from '@shared/libs';
import { useModal } from '@hooks/useModal';

export type ExplorerAddressTransactionsProps = {
  address: string;
  network: string;
};
const pageSize = 10;
export const ExplorerAddressTransactions: FC<ExplorerAddressTransactionsProps> = ({
  network,
  address,
}) => {
  const openTransactionModal = useModal('explorerTransaction', {
    contentProps: {
      style: { right: 0, top: 0, transform: 'none', left: 'unset' },
    },
  });
  const { t } = useTranslation();
  const networkEnum = getNetworkEnum(network);

  const listButtons: ToggleItem<AddressTransactionsFilterTxType>[] = [
    { value: AddressTransactionsFilterTxType.All, label: t('strings.all') },
    { value: AddressTransactionsFilterTxType.Receives, label: t('strings.received') },
    { value: AddressTransactionsFilterTxType.Sent, label: t('strings.sent') },
  ];

  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<
    Maybe<{
      field: AddressTransactionsOrderField;
      direction: OrderDirection;
    }>
  >({
    field: AddressTransactionsOrderField.Timestamp,
    direction: OrderDirection.Desc,
  });
  const [txType, setTxType] = useState<AddressTransactionsFilterTxType>(
    AddressTransactionsFilterTxType.All,
  );

  const {
    loading: isLoadingTransactions,
    data: transactionsData,
    previousData: transactionsPrevData,
  } = useExplorerFindTransactionsByAddressQuery({
    variables: {
      network: networkEnum,
      filter: {
        address,
        order: sort,
        page,
        pageSize,
        txType,
      },
    },
  });

  const { data: totalData, previousData: prevTotalData } =
    useExplorerFindTransactionsByAddressCountQuery({
      variables: {
        network: networkEnum,
        filter: {
          address,
          txType,
        },
      },
    });
  const renderVisualization = (txid: string, network: string): JSX.Element => {
    return (
      <div className={cx(styles.wrapper__cell, styles.wrapper__cell_right)}>
        <Button
          onClick={() => openVisualizationTransaction(network, txid)}
          variant="outlined"
          size={'small'}
          prependIcon={() => VisualizationDenseIcon({ color: '#394363' })}
          icon
        />
      </div>
    );
  };

  const renderArrowIcon = (type: AddressTransactionsFilterTxType): JSX.Element => {
    return (
      <div className={styles.wrapper}>
        {type === AddressTransactionsFilterTxType.Receives ? (
          <ArrowLeftLongIcon color="var(--success-2)" />
        ) : (
          <ArrowRightLongIcon color="var(--error-2)" />
        )}
      </div>
    );
  };

  const rowTemplate = (item: AddressTransaction) => {
    const type =
      item.direction === 'sends'
        ? AddressTransactionsFilterTxType.Sent
        : AddressTransactionsFilterTxType.Receives;
    const isSent = type === AddressTransactionsFilterTxType.Sent;
    const recvCount = item.recvAddr.length;
    const sendCount = item.senderAddr.length;
    const isMultiTransaction = recvCount > 1 || sendCount > 1;
    const handleOpenTrModal = () =>
      openTransactionModal({ transaction: item, network: networkEnum });

    return {
      key: item.txID,
      chevron: isMultiTransaction
        ? renderCellChevron({
            onClick: handleOpenTrModal,
          })
        : ' ',
      timestamp: renderCellDate({ timestamp: item.timestamp }),
      hash: renderCellHash({
        hash: item.txID,
        network: networkEnum,
        type: 'transaction',
        longer: true,
        className: styles.wrapper__cell_hash,
      }),
      token: item.token ? item.token.toUpperCase() : networkEnum,
      sender_address: renderCellReceiveAddresses({
        items: isSent ? item.senderAddr : item.recvAddr,
        network,
        longer: true,
        className: styles.wrapper__cell_hash,
      }),
      arrow_left: renderArrowIcon(type),
      amount: renderCellAmount({
        amount: item.amount,
        network,
        type,
        style: {
          width: '102px',
        },
      }),
      arrow_right: renderArrowIcon(type),
      receiving_address: renderCellReceiveAddresses({
        items: !isSent ? item.senderAddr : item.recvAddr,
        network,
        showCounter: true,
        longer: true,
        className: styles.wrapper__cell_hash,
        onClickCounter: handleOpenTrModal,
      }),
      visualization:
        isMultiTransaction || networkEnum === Network.Btc // TEMP
          ? ' '
          : renderVisualization(item.txID, networkEnum),
    };
  };

  const items = useMemo(
    () =>
      (
        transactionsData?.explorerFindTransactionsByAddress?.edge ||
        transactionsPrevData?.explorerFindTransactionsByAddress?.edge ||
        []
      ).map(rowTemplate),
    [transactionsData],
  );
  const total = useMemo(
    () =>
      Number(
        totalData?.explorerFindTransactionsByAddressCount.total ||
          prevTotalData?.explorerFindTransactionsByAddressCount.total ||
          0,
      ),
    [totalData],
  );

  useEffect(() => {
    setPage(1);
    setTxType(AddressTransactionsFilterTxType.All);
  }, [address]);

  const onChangeHandler = (value: AddressTransactionsFilterTxType) => {
    setPage(1);
    setTxType(value);
  };

  const handleSort = (sort: SortType, sortBy: string) => {
    setSort(
      sort
        ? {
            field: sortBy as AddressTransactionsOrderField,
            direction: sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc,
          }
        : null,
    );
  };

  return (
    <>
      <div>
        <Toggle
          key={txType}
          initValue={txType}
          items={listButtons}
          onChange={onChangeHandler}
          size="small"
        />
      </div>
      <Spacer size={12} />
      <TableController
        data={items}
        headers={headers()}
        total={total}
        onChangePage={setPage}
        initSortBy={sort?.field}
        initSort={sort?.direction}
        onSort={handleSort}
        initPage={page}
        pageSize={pageSize}
        scrollToTop={false}
      />
      <LoaderOverlay fixed offset={{ left: '150px' }} show={isLoadingTransactions} />
    </>
  );
};
