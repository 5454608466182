import { FC } from 'react';
import { NodeItem } from '@graph/types';
import { useMenuNodeEvent } from '../../libs/hooks/useMenuNodeEvent';
import { DropdownItem, InteractionIcon, TrashDenseIcon } from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';

export type NodeMenuProps = {
  node: NodeItem;
  onSubmit(): void;
};
export const NodeMenu: FC<NodeMenuProps> = ({ node, onSubmit }) => {
  const { t } = useTranslation();
  const { onRemove, onAddAnnotation } = useMenuNodeEvent(node, onSubmit);

  return (
    <>
      <DropdownItem
        label={t('buttons.addAnnotation')}
        fullWidth
        icon={InteractionIcon}
        onClick={onAddAnnotation}
      />
      {/*<DropdownItem label={t('buttons.pin')} fullWidth icon={PinIcon} />*/}
      <DropdownItem
        label={t('buttons.delete')}
        fullWidth
        icon={TrashDenseIcon}
        onClick={onRemove}
      />
    </>
  );
};
