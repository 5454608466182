import React, { FC, useMemo, useState } from 'react';
import {
  headersTransactionDetailReceiveTable,
  headersTransactionDetailSentTable,
  TransactionDetailBody,
  TransactionDetailHead,
} from '@component/Transaction';
import {
  Divider,
  LoaderOverlay,
  Spacer,
  TableController,
  Typography,
} from '@rubin-dev/goblin';
import {
  AddressByTransaction,
  AddressTransactionsFilterTxType,
  Network,
  useExplorerFindBlockByTransactionQuery,
  useExplorerFindTransactionByHashQuery,
  useExplorerIncomingAddressListByTransactionQuery,
  useExplorerOutcomingAddressListByTransactionQuery,
} from '@apolloGenerated';
import sdk from '@aml/sdk';
import { getClusterLabel, getNetworkEnum } from '@helpers/address';
import { useTranslation } from 'react-i18next';
import { router, RouterName } from '../../../../router';
import { openAddress, openWallet } from '@shared/libs';
import { renderCellAmount, renderCellHash } from '@shared/ui';
import { TransactionDetailContainer } from '@graph/modules';
import styles from './styles.module.scss';

type ExplorerTransactionDetailsScreenProps = {
  params: {
    txid: string;
    network: Network;
  };
};
export const PAGE_SIZE = 10;
export const ExplorerTransactionDetailsScreen: FC<
  ExplorerTransactionDetailsScreenProps
> = ({ params: { txid, network } }): JSX.Element => {
  const { t } = useTranslation();
  const networkEnum = getNetworkEnum(network);
  const getQueryParamsAddresses = (page: number) => ({
    variables: {
      network: networkEnum,
      txid,
      page,
      pageSize: PAGE_SIZE,
    },
  });
  const { data: riskData, isLoading: isLoadingRisk } =
    sdk.risk[networkEnum].queries.useRiskServiceTransactionRisk(txid);
  const { data: trData, loading: isLoadingTr } = useExplorerFindTransactionByHashQuery({
    variables: {
      id: txid,
      network: networkEnum,
    },
  });
  const token = trData?.explorerFindTransactionByHash?.transaction?.token || network;
  const { data: blockData, loading: isLoadingBlock } =
    useExplorerFindBlockByTransactionQuery({
      variables: {
        network: networkEnum,
        txid,
      },
    });
  const block = blockData?.explorerFindBlockByTransaction.node;
  const [pageOutcoming, setPageOutcoming] = useState(1);
  const [pageIncoming, setPageIncoming] = useState(1);
  const {
    data: outcomingData,
    loading: isLoadingOutcoming,
    previousData: prevOutcomingData,
  } = useExplorerOutcomingAddressListByTransactionQuery(
    getQueryParamsAddresses(pageOutcoming),
  );
  const {
    data: incomingData,
    loading: isLoadingIncoming,
    previousData: prevIncomingData,
  } = useExplorerIncomingAddressListByTransactionQuery(
    getQueryParamsAddresses(pageIncoming),
  );
  const isLoading =
    isLoadingRisk ||
    isLoadingBlock ||
    isLoadingIncoming ||
    isLoadingOutcoming ||
    isLoadingTr;

  const handleClickVisualization = () => {
    window.open(
      router.urlFor(RouterName.ExplorerTransactionVisualization, {
        txid,
        network,
      }),
      '_blank',
      'noopener,noreferrer',
    );
  };
  const getRowTemplate =
    (type: AddressTransactionsFilterTxType) => (item: AddressByTransaction) => ({
      hash: (
        <div>
          {renderCellHash({
            hash: item.address,
            type: 'address',
            network,
          })}
          {item.cluster && (
            <Typography variant="body-12" color="on-surface-secondary-2">
              {t('mention.cluster')}:{' '}
              <Typography
                tag="span"
                variant="body-12"
                color="text-link"
                className="cursor-pointer"
                onClick={() => openWallet(network, item.cluster!.id)}
              >
                {getClusterLabel(item.cluster)}
              </Typography>
            </Typography>
          )}
        </div>
      ),
      hashUniq: item.address,
      amount: renderCellAmount({ amount: item.summ!, network, type }),
      amountFloat: item.summ,
    });
  // Edge
  const edgeOutcoming = useMemo(
    () =>
      (
        outcomingData?.explorerOutcomingAddressListByTransaction.edge ||
        prevOutcomingData?.explorerOutcomingAddressListByTransaction.edge ||
        []
      ).map(getRowTemplate(AddressTransactionsFilterTxType.Receives)),
    [outcomingData, getRowTemplate],
  );
  const edgeIncoming = useMemo(
    () =>
      (
        incomingData?.explorerIncomingAddressListByTransaction.edge ||
        prevIncomingData?.explorerIncomingAddressListByTransaction.edge ||
        []
      ).map(getRowTemplate(AddressTransactionsFilterTxType.Sent)),
    [incomingData],
  );
  // Total
  const totalOutcoming = useMemo<number>(
    () =>
      outcomingData?.explorerOutcomingAddressListByTransaction.total ||
      prevOutcomingData?.explorerOutcomingAddressListByTransaction.total ||
      0,
    [outcomingData],
  );
  const totalIncoming = useMemo<number>(
    () =>
      incomingData?.explorerIncomingAddressListByTransaction.total ||
      prevIncomingData?.explorerIncomingAddressListByTransaction.total ||
      0,
    [incomingData],
  );
  const isManyOutcomingOrIncoming = useMemo<boolean>(
    () => totalIncoming > 1 || totalOutcoming > 1,
    [totalIncoming, totalOutcoming],
  );

  return (
    <div className={styles.trDetails}>
      <div
        className={styles.trDetails__wrapper}
        style={{ width: `${isManyOutcomingOrIncoming ? 749 : 558}px` }}
      >
        {isLoading && <LoaderOverlay show />}
        <div className={styles.trDetails__header}>
          <Typography tag="h2" variant="head-20">
            {t('titles.trdetails')}
          </Typography>
        </div>
        <Divider />
        <div className={styles.trDetails__content}>
          <TransactionDetailHead
            network={network}
            token={isManyOutcomingOrIncoming ? token : null}
            hash={txid}
            risk={riskData?.risk?.risk}
            block={block?.height}
            timestamp={block?.timestamp}
          />
          <Spacer size={12} />
          <Divider />
          <Spacer size={12} />
          {isManyOutcomingOrIncoming ? (
            <TransactionDetailContainer
              sendingSlot={
                <TableController
                  data={edgeIncoming}
                  headers={headersTransactionDetailReceiveTable()}
                  total={totalIncoming}
                  initPage={pageIncoming}
                  pageSize={PAGE_SIZE}
                  onChangePage={setPageIncoming}
                  columnPagination
                />
              }
              receivingSlot={
                <TableController
                  data={edgeOutcoming}
                  headers={headersTransactionDetailSentTable()}
                  total={totalOutcoming}
                  initPage={pageOutcoming}
                  pageSize={PAGE_SIZE}
                  onChangePage={setPageOutcoming}
                  columnPagination
                />
              }
            />
          ) : (
            <TransactionDetailBody
              receivingAddress={edgeOutcoming[0]?.hashUniq}
              sendingAddress={edgeIncoming[0]?.hashUniq}
              amount={edgeOutcoming[0]?.amountFloat || edgeIncoming[0]?.amountFloat}
              network={network}
              onClickAddress={(address) => openAddress(network, address)}
              onClickVisualization={handleClickVisualization}
              token={token}
            />
          )}
        </div>
      </div>
    </div>
  );
};
