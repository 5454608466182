import React, { FC, useEffect, useState } from 'react';
import styles from '@component/Marking/contentMarking.module.scss';
import { ArrowLeftIcon, Button } from '@rubin-dev/goblin';
import { FormMarking, MarkingForm } from '@component/Marking/FormMarking';
import { useTranslation } from 'react-i18next';
import { RouterName } from '../../router';
import {
  Network,
  useExplorerFindMentionsForAddressLazyQuery,
  useExplorerMarkupCreateMutation,
  useExplorerScreenshotUploadMutation,
} from '@apolloGenerated';
import { detectNetworkByAddress } from '@aml/validation';
import { useNavigate } from 'react-justanother-router';
import { useModal } from '@hooks/useModal';
import { getNetworkEnum } from '@helpers/address';
import { uuidv7 } from 'uuidv7';

interface Props {
  address: string;
}

export const MarkingCreateComponent: FC<Props> = ({ address }) => {
  const { t } = useTranslation();
  const { navigate } = useNavigate();

  const [currentAddress, setCurrentAddress] = useState<string | null>(null);
  const [fetchMentions] = useExplorerFindMentionsForAddressLazyQuery();

  const openDialog = useModal('dialogSuccess');

  const [mutate] = useExplorerMarkupCreateMutation();
  const [mutateUploadScreenshot] = useExplorerScreenshotUploadMutation();

  const onSuccess = () => {
    fetchMentions({
      variables: {
        network: getNetworkEnum(String(detectNetworkByAddress(address)?.toUpperCase())),
        address,
      },
      fetchPolicy: 'network-only',
    });
  };

  const onSubmit = async (values: MarkingForm) => {
    const customUUID = uuidv7();
    const currentValues = {
      ...values,
    };

    setCurrentAddress(currentValues?.address || '');

    if (currentValues.screenshots && currentValues.screenshots.length) {
      await mutateUploadScreenshot({
        variables: {
          req: {
            uuid: customUUID,
            screenshots: currentValues.screenshots,
          },
        },
      });
    }

    delete currentValues.screenshots;

    await mutate({
      variables: {
        // @ts-ignore
        req: {
          ...currentValues,
          uuid: customUUID,
          network: detectNetworkByAddress(String(address))?.toUpperCase() as Network,
          ...(currentValues?.otherAddresses
            ? {
                otherAddresses: currentValues?.otherAddresses.map((item) => {
                  return {
                    network: detectNetworkByAddress(String(item))?.toUpperCase(),
                    address: item,
                  };
                }),
              }
            : {}),
          level: 160,
          mentionSourceID: 38,
        },
      },
    });

    openDialog({ onSuccess });
  };

  useEffect(() => {
    if (currentAddress) {
      navigate(RouterName.Marking, {}, { query: currentAddress });
    }
  }, [currentAddress]);

  const onPrevPage = () => {
    navigate(RouterName.Marking, {}, { query: address });
  };

  return (
    <section className={styles.section}>
      <Button
        variant={'outlined'}
        icon
        prependIcon={ArrowLeftIcon}
        onClick={onPrevPage}
      />
      <FormMarking title={t('marking.add')} formOnSubmit={onSubmit} address={address} />
    </section>
  );
};
