import React, { FC, useMemo } from 'react';
import { timestampToHumanDate } from '@utils/timestamp';
import { RiskDot } from '@component/NewRisk';
import { Maybe, useMeQuery } from '@apolloGenerated';
import { useTranslation } from 'react-i18next';
import { CopyHash, Typography } from '@rubin-dev/goblin';
import { Blur, ListItem } from '@shared/ui';
import { RouterName } from '../../../../router';

interface TransactionDetailHeadProps {
  token?: Maybe<string>;
  timestamp?: Maybe<number>;
  hash?: Maybe<string>;
  network?: Maybe<string>;
  block?: Maybe<string>;
  risk?: Maybe<number>;
}

export const TransactionDetailHead: FC<TransactionDetailHeadProps> = ({
  hash,
  timestamp,
  token,
  network,
  block,
  risk,
}) => {
  const { data: user } = useMeQuery({ fetchPolicy: 'network-only' });
  const { t } = useTranslation();
  const expireDate = useMemo(
    () => user?.me.billing.analyzerSubscriptionExpireDate,
    [user?.me.billing.analyzerSubscriptionExpireDate],
  );
  return (
    <div className={'b-transaction-detail-head'}>
      {!!network && (
        <ListItem
          label={`${t('titles.blockchain')}:`}
          value={network.toUpperCase()}
          row
        />
      )}
      {!!token && (
        <ListItem label={`${t('titles.token')}:`} value={token.toUpperCase()} row />
      )}
      {!!timestamp && (
        <ListItem
          label={'Date (UTC)'}
          value={`${timestampToHumanDate(timestamp, 'date')}, 
          ${timestampToHumanDate(timestamp, 'time')}`}
          row
        />
      )}
      {!!hash && (
        <ListItem
          label={`${t('titles.hash')}:`}
          value={<CopyHash hash={hash} variant="head-16" shorted />}
          row
        />
      )}
      {!isNaN(Number(risk)) && (
        <ListItem
          label={`${t('strings.riskscore')}:`}
          value={
            expireDate ? (
              <Typography variant="head-16" className="b-transaction-detail-head__risk">
                <RiskDot risk={+risk!} />
                {risk}%
              </Typography>
            ) : (
              <Blur to={RouterName.TariffAnalyzer}>
                <RiskDot risk={Number(risk)} size={8} />
                000%
              </Blur>
            )
          }
          row
        />
      )}
      {!!block && <ListItem label={`${t('links.block')}:`} value={String(block)} row />}
    </div>
  );
};
